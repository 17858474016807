export type DeadlineInterval = {
  unit: 'DAYS' | 'WEEKS' | 'MONTHS'
  value: number
}

export function convertDaysToDeadlineInterval(
  deadlineIntervalDays: number
): DeadlineInterval {
  if (deadlineIntervalDays % 30 === 0) {
    return {
      unit: 'MONTHS',
      value: deadlineIntervalDays / 30,
    }
  }

  if (deadlineIntervalDays % 7 === 0) {
    return {
      unit: 'WEEKS',
      value: deadlineIntervalDays / 7,
    }
  }

  return {
    unit: 'DAYS',
    value: deadlineIntervalDays,
  }
}

export function convertDeadlineIntervalToDays(
  deadlineInterval: DeadlineInterval
): number {
  switch (deadlineInterval.unit) {
    case 'DAYS':
      return deadlineInterval.value
    case 'WEEKS':
      return deadlineInterval.value * 7
    case 'MONTHS':
      return deadlineInterval.value * 30
  }
}
