import { type ProjectSchema, type StatusSchema } from '@motion/rpc-types'
import {
  type DeadlineStatus,
  isCanceledStatus,
  isCompletedStatus,
} from '@motion/shared/common'
import { groupByKey } from '@motion/utils/array'
import { daysBetweenDates } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { type ExtendedDeadlineStatus, getEtaLabel } from './general'

import { formatToReadableWeekDayMonth, templateStr } from '../../utils'

type PluralizeFunction = (
  numDays: number,
  singular: string,
  plural: string
) => string | React.ReactNode

// We allow null here because in the project modal we have a null check for the project
export const normalizeProjectDeadlineStatus = (
  project: ProjectSchema | null
): DeadlineStatus => {
  return project && project.deadlineStatus ? project.deadlineStatus : 'none'
}

export const getProjectEtaTitle = (
  project: ProjectSchema,
  pluralize: PluralizeFunction
) => {
  const deadlineStatus = normalizeProjectDeadlineStatus(project)
  const label = getEtaLabel(deadlineStatus, 'project', project.scheduledStatus)

  if (deadlineStatus === 'ahead-of-schedule') {
    // Append the number of days ahead of schedule
    const scheduledDate =
      project.type === 'NORMAL' && project.estimatedCompletionTime
        ? project.estimatedCompletionTime
        : null

    const daysDiff =
      project.dueDate && scheduledDate
        ? daysBetweenDates(
            DateTime.fromISO(project.dueDate),
            DateTime.fromISO(scheduledDate)
          )
        : 0

    return templateStr('{{label}} {{daysModifier}}', {
      label,
      daysModifier:
        daysDiff > 0
          ? pluralize(daysDiff, 'by 1 day', `by ${daysDiff} days`)
          : '',
    })
  }

  return label
}

export const getProjectEtaReason = (
  project: ProjectSchema,
  pluralize: PluralizeFunction
) => {
  const deadlineStatus = normalizeProjectDeadlineStatus(project)

  const scheduledDate =
    project.type === 'NORMAL' && project.estimatedCompletionTime
      ? project.estimatedCompletionTime
      : null

  const dateDue = formatToReadableWeekDayMonth(project.dueDate ?? '')

  const daysDiff =
    project.dueDate && scheduledDate
      ? Math.abs(
          daysBetweenDates(
            DateTime.fromISO(project.dueDate),
            DateTime.fromISO(scheduledDate)
          )
        )
      : 0

  const pluralizedText = pluralize(daysDiff, 'day', 'days')

  switch (deadlineStatus) {
    case 'missed-deadline':
      return project.dueDate
        ? templateStr('Due {{dateDue}} ({{daysAgo}})', {
            dateDue,
            daysAgo: DateTime.fromISO(project.dueDate).toRelative(),
          })
        : ''
    case 'scheduled-past-deadline': {
      if (project.scheduledStatus === 'UNFIT_PAST_DUE') {
        return "This is because 1 or more tasks can't fit within Motion's 92 day auto-schedule window"
      }

      return scheduledDate
        ? templateStr(
            'Due {{dateDue}}. Last task is scheduled {{daysDiff}} {{pluralizedText}} after',
            {
              dateDue,
              daysDiff,
              pluralizedText,
            }
          )
        : ''
    }

    case 'on-track':
      return scheduledDate
        ? templateStr('Due {{dateDue}} {{scheduledText}}', {
            dateDue,
            scheduledText:
              daysDiff > 0
                ? templateStr(
                    '({{daysDiff}} {{pluralizedText}} after scheduled date)',
                    {
                      daysDiff,
                      pluralizedText,
                    }
                  )
                : '(Scheduled on due date)',
          })
        : ''
    case 'ahead-of-schedule':
      return scheduledDate
        ? templateStr(
            'Due {{dateDue}} (estimated to be completed {{dayScheduled}})',
            {
              dateDue,
              dayScheduled: formatToReadableWeekDayMonth(scheduledDate),
            }
          )
        : ''
    default:
      return ''
  }
}

export const getExtendedProjectDeadlineStatus = (
  project: ProjectSchema | null,
  workspaceStatuses: StatusSchema[] = []
): ExtendedDeadlineStatus => {
  if (project === null) return 'none'
  const normalizedProjectDeadlineStatus =
    normalizeProjectDeadlineStatus(project)

  if (normalizedProjectDeadlineStatus !== 'none')
    return normalizedProjectDeadlineStatus

  const workspacesMap = groupByKey(workspaceStatuses, 'id')
  const projectStatus = workspacesMap[project.statusId]?.[0] ?? null
  if (!projectStatus) return 'none'

  if (isCompletedStatus(projectStatus)) return 'completed'
  if (isCanceledStatus(projectStatus)) return 'cancelled'

  return 'none'
}

export const getProjectNoEtaReason = (
  project: ProjectSchema,
  workspaceStatuses: StatusSchema[] = []
) => {
  const projectDeadlineStatus = getExtendedProjectDeadlineStatus(
    project,
    workspaceStatuses
  )

  if (
    projectDeadlineStatus !== 'none' &&
    projectDeadlineStatus !== 'cancelled' &&
    projectDeadlineStatus !== 'completed'
  )
    return null

  if (projectDeadlineStatus === 'completed') {
    return 'Project complete'
  } else if (projectDeadlineStatus === 'cancelled') {
    return 'Project canceled'
  } else if (!project.scheduledStatus) {
    return 'No ETA because there are no auto-scheduled tasks in this project'
  }

  return 'No ETA'
}
